import React, {useEffect, useState} from 'react';
import {Button, EmptyBox, Icons, Preloader} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {BarGraph, Details} from 'components';
import {useRequest} from 'common/hooks';
import {useStatsContext} from 'pages/stats/stats-context';
import * as AppEnv from 'app-env';

enum Colors {
  Blue = '#56caf0',
  Green = '#83d11f'
}

const Payments = () => {
  const {
    filter,
    isFilterAvailableAndActive,
    setIsFilterActive,
    setIsFilterDisabled
  } = useStatsContext();

  const [color, setColor] = useState<Colors>(Colors.Blue);
  const [currency, setCurrency] = useState<'rub' | 'usd'>('rub');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [stats, setStats] = useState<AppEnv.PaymentStats[] | null>();

  const {fetchStatistics} = useRequest();
  const {i18n, t} = useTranslation();

  const getStats = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data} = await fetchStatistics<AppEnv.PaymentStats[] | object>(
      'payments',
      filter
    );

    const newCurrency =
      filter.currency == 'rub' || filter.currency == 'usd'
        ? filter.currency
        : 'rub';

    setStats(Array.isArray(data) ? data : []);
    setColor(newCurrency == 'rub' ? Colors.Blue : Colors.Green);
    setCurrency(newCurrency);
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
  }, [filter]);

  useEffect(() => {
    setIsFilterDisabled(!stats);
  }, [stats]);

  useEffect(() => {
    document.title = `${t`Payment statistics`} - Whatcrm Console`;
  }, []);

  const data = stats?.map(item => ({
    [currency]: item.sum,
    date_period: item.date_period
  }));

  const total =
    stats?.reduce((res, item) => {
      res += item.sum;
      return res;
    }, 0) || 0;

  const average = total && stats ? total / stats.length : 0;

  const details = [
    {
      icon: <Icons.Summation />,
      label: t`Amount of payments`,
      value: total.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    },
    {
      icon: <Icons.Wave />,
      label:
        filter.report == 'month' ? t`Average per month` : t`Average per day`,
      value: average.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    }
  ];

  const keys = {[currency]: {color, value: 0}};

  return (
    <>
      <Button
        color={isFilterAvailableAndActive ? 'active' : 'white'}
        style={{marginBottom: 16}}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        {t`Filter`}
      </Button>

      {data ? (
        data?.length ? (
          <>
            <Details details={details} style={{marginBottom: 32}} isAutoWidth />
            <BarGraph data={data} keys={keys} />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default Payments;
