import React from 'react';
import {Box, Wrapper} from 'whatcrm-core';

import * as AppEnv from 'app-env';

import Header from './header/header';
import PaymentInfo from './payment-info/payment-info';
import BasicInfo from './system-info/system-info';
import IntegrationInfo from './integration-info/integration-info';
import UserInfo from './user-info/user-info';
import Actions from './actions/actions';
import ConnectionContext from './connection-context';

interface Connection {
  connection: AppEnv.Instance;
}

const Connection = ({connection}: Connection) => (
  <ConnectionContext.Provider value={{connection}}>
    <Box color={connection.is_deleted ? 'red' : undefined}>
      <Wrapper
        justifyContent="space-between"
        isColumn
        style={{height: '100%'}}
      >
        <Wrapper
          gap={12}
          isColumn
        >
          <Header />
          <PaymentInfo />
          <BasicInfo />
          <IntegrationInfo />
          <UserInfo />
        </Wrapper>

        <Actions />
      </Wrapper>
    </Box>
  </ConnectionContext.Provider>
);

export default Connection;
