import React from 'react';
import {Text} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

export interface Key {
  color: string;
  label?: string;
  value: number;
}

interface Payload {
  name: string | undefined;
  rechartsKey: Key | undefined;
  value: number | undefined;
}

const Payload = ({name, rechartsKey, value}: Payload) => {
  const {i18n} = useTranslation();

  return (
    <Text color="dark" weight="bold" isParagraph>
      {name == 'rub' || name == 'usd' ? (
        value?.toLocaleString(i18n.resolvedLanguage, {
          style: 'currency',
          currency: name
        })
      ) : (
        <>
          {rechartsKey && `${rechartsKey.label} — `}
          {value?.toLocaleString(i18n.resolvedLanguage)}
        </>
      )}
    </Text>
  );
};

export default Payload;
