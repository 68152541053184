import React, {useState, useEffect} from 'react';
import {Title, Wrapper, Input, Preloader, EmptyBox} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {BarGraph, Legend} from 'components';
import {useRequest} from 'common/hooks';

type Active = 'rub' | 'usd';

interface Payment {
  date_period: string;
  sum: number;
}

interface Payments {
  rub: Payment[];
  usd: Payment[];
}

interface Stats {
  date_period: string;
  rub: number;
  usd: number;
}

const Payments = () => {
  const [stats, setStats] = useState<Stats[]>();
  const [active, setActive] = useState<Active>('rub');

  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();

  const getStats = async () => {
    const {data} = await fetchStatistics<Payments>('partners-payments', {
      end,
      start
    });

    if (data?.rub) {
      const res = data.rub.map(item => ({
        date_period: item.date_period,
        rub: item.sum,
        usd: 0
      }));

      data.usd?.forEach(item => {
        const index = res.findIndex(
          entity => item.date_period == entity.date_period
        );

        if (index > -1) {
          res[index].usd = item.sum;
          return;
        }

        res.push({date_period: item.date_period, rub: 0, usd: item.sum});
      });

      setStats(res);
      return;
    }

    setStats([]);
  };

  useEffect(() => {
    if (start && end) {
      setStats(undefined);
      getStats();
    }
  }, [start, end]);

  useEffect(() => {
    getStats();
  }, []);

  const {rub = 0, usd = 0} = stats
    ? stats.reduce(
        (res, item) => {
          res.rub += item.rub;
          res.usd += item.usd;
          return res;
        },
        {rub: 0, usd: 0}
      )
    : {};

  const keys = {
    rub: {color: '#ff8242', label: 'RUB', value: rub},
    usd: {color: '#83d11f', label: 'USD', value: usd}
  };

  return (
    <div>
      <Title.H2 style={{marginBottom: 8}}>{t`Payments`}</Title.H2>

      <Wrapper gap={8} style={{marginBottom: 32}}>
        <div>
          <Title.H3 style={{marginBottom: 8}}>{t`Start`}</Title.H3>

          <Input
            name="start"
            type="date"
            value={start}
            max={moment().format('yyyy-MM-DD')}
            onChange={setStart}
          />
        </div>

        <div>
          <Title.H3 style={{marginBottom: 8}}>{t`End`}</Title.H3>

          <Input
            name="end"
            type="date"
            value={end}
            max={moment().format('yyyy-MM-DD')}
            onChange={setEnd}
          />
        </div>
      </Wrapper>

      {stats ? (
        stats.length ? (
          <BarGraph data={stats} keys={{[active]: keys[active]}}>
            <Legend<Active>
              data={keys}
              value={active}
              isIndependent
              onClick={setActive}
            />
          </BarGraph>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default Payments;
