import React from 'react';
import {Text, Wrapper} from 'whatcrm-core';
import {TooltipProps} from 'recharts';

import Payload, {Key} from './payload/payload';

export interface Keys {
  [index: string]: Key;
}

interface GraphTooltip {
  keys: Keys;
}

const GraphTooltip = ({
  active,
  keys,
  payload
}: GraphTooltip & TooltipProps<number, string>) => {
  const isActive = active && payload?.length;

  return (
    <>
      {isActive ? (
        <div className="recharts-tooltip">
          <Text size="s" isParagraph style={{marginBottom: 8}}>
            {payload[0].payload.date_period}
          </Text>

          <Wrapper gap={8} isColumn>
            {payload.map((item, i) => (
              <Payload
                key={i}
                name={item.name}
                rechartsKey={item.name ? keys[item.name] : undefined}
                value={item.value}
              />
            ))}
          </Wrapper>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default GraphTooltip;
