import React from 'react';
import Code from '../../code';

interface Value {
  value: unknown;
}

const Value = ({value}: Value) => {
  const isString = typeof value == 'string';

  const color = isString
    ? '#ff8242'
    : typeof value == 'number'
    ? '#a892e7'
    : '#5bc6ea';

  const isObject =
    value && typeof value == 'object' && Object.keys(value).length;

  return (
    <>
      {isObject ? (
        <Code code={JSON.stringify(value)} />
      ) : (
        <>
          <span style={{color}}>
            {isString && "'"}
            {value != null ? (value as React.ReactNode) : 'null'}
            {isString && "'"}
          </span>
          ,
        </>
      )}
    </>
  );
};

export default Value;
