import React, {useState} from 'react';
import {Line, Wrapper, Button, Anchor} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';
import {useRequest} from 'common/hooks';

import Screenshot from './screenshot/screenshot';

interface ScreenshotSuccess {
  screenshot: string;
}

interface ScreenshotError {
  error: string | number;
  error_code: string | number;
  error_text: string;
}

const Actions = () => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const [screenshot, setScreenshot] = useState<string>();
  const [isRebooting, setIsRebooting] = useState(false);
  const [isScreenshoting, setIsScreenshoting] = useState(false);

  const {updateInstance} = useRequest();
  const {t} = useTranslation();

  const reboot = async () => {
    setIsRebooting(true);

    const {data} = await updateInstance<{success: 1 | 'ok'}>(
      connection.chat_key,
      'reboot'
    );

    if (data) {
      pushNotification(
        `${t`Connection`} ${connection.id}`,
        t`The connection has been rebooted`
      );
    }

    setIsRebooting(false);
  };

  const updateScreenshot = (data: ScreenshotSuccess | ScreenshotError) => {
    if ('screenshot' in data) {
      setScreenshot(data.screenshot);
      return;
    }

    pushNotification(
      `${t`Error`}${
        typeof data.error_code == 'number' ? ` ${data.error_code}` : ''
      }`,
      data.error_text
    );
  };

  const getScreenshot = async () => {
    setIsScreenshoting(true);

    const {data} = await updateInstance<ScreenshotSuccess | ScreenshotError>(
      connection.chat_key,
      'screenshot'
    );

    if (data) {
      updateScreenshot(data);
    }

    setIsScreenshoting(false);
  };

  return (
    <>
      <div>
        <Line />

        <Wrapper gap={8}>
          <Link
            to={`/connections/${connection.id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="white" tip={t`Open the payments`}>
              <Unicons.UilTransaction className="core-icon icon" />
            </Button>
          </Link>

          <Anchor
            href={`${process.env.REACT_APP_WHATCRM}/scanner/${connection.chat_key}`}
            target="_blank"
            isDisabled={connection.status == 'READY'}
          >
            <Button color="white" tip={t`Open the QR code`}>
              <Unicons.UilQrcodeScan className="core-icon icon" />
            </Button>
          </Anchor>

          <Button
            color="white"
            tip={t`Reboot`}
            isDisabled={isRebooting}
            onClick={reboot}
          >
            <Unicons.UilRedo className="core-icon icon" />
          </Button>

          {connection.version == 'whatcrm' && (
            <Button
              color="white"
              tip={t`Take a screenshot`}
              isDisabled={isScreenshoting}
              onClick={getScreenshot}
            >
              <Unicons.UilCapture className="core-icon icon" />
            </Button>
          )}
        </Wrapper>
      </div>

      <Screenshot
        screenshot={screenshot}
        isScreenshoting={isScreenshoting}
        setScreenshot={setScreenshot}
        getScreenshot={getScreenshot}
      />
    </>
  );
};

export default Actions;
